import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { getDatabase, ref, onValue, update, push } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";

const EditarPerfilRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
  color: white;
  font-family: var(--font-roboto);
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #fff;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1.5rem;
  border: none;
  border-bottom: 2px solid white;
  background-color: transparent;
  color: white;
  text-align: left;
  outline: none;
  caret-color: white;

  ::placeholder {
    color: #999;
  }
`;

const RoleSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 1.2rem;
  background-color: #444;
  color: white;
  border: none;
  border-radius: 5px;

  option {
    background-color: #444;
    color: white;
  }
`;

const FileInput = styled.input`
  margin-top: 20px;
  color: white;
`;

const ContinueButton = styled.button`
  background-color: #000;
  color: #ff9900;
  padding: 12px 40px;
  font-size: 1.25rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #333;
  }
`;

const SuccessMessage = styled.div`
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  display: ${(props) => (props.show ? "block" : "none")};
`;

const UserList = styled.ul`
  list-style: none;
  width: 100%;
  padding: 0;
`;

const UserListItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #333;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #444;
  }
`;

const AdminSection = styled.div`
  width: 100%;
  margin-top: 40px;
`;

const BackButton = styled.button`
  background-color: transparent;
  color: #ff9900;
  font-size: 1.2rem;
  border: none;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const NewUserForm = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color: #222;
  border-radius: 5px;
`;

const NewUserButton = styled.button`
  background-color: #ff9900;
  color: #000;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #cc7a00;
  }
`;

const EditarPerfil = () => {
  const { userId } = useParams();
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState(null);
  const [role, setRole] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [editingOtherUser, setEditingOtherUser] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserRole, setNewUserRole] = useState("nutri");
  const [newUserPhoto, setNewUserPhoto] = useState(null);
  const [newUserPassword, setNewUserPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase();
    const userRef = ref(db, `users/${userId}`);
    const roleRef = ref(db, `users/${userId}/role`);

    // Fetch user information
    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      if (userData) {
        setName(userData.name || "");
        setPhoto(userData.photo || null);
      }
    });

    // Fetch role to check if user is an admin
    onValue(roleRef, (snapshot) => {
      const userRole = snapshot.val();
      setRole(userRole || "nutri"); // Default role is "nutri" (nutritionist)
      setIsAdmin(userRole === "admin");
    });

    // Fetch all users for admin view
    if (isAdmin) {
      const usersRef = ref(db, "users");
      onValue(usersRef, (snapshot) => {
        const usersData = snapshot.val();
        if (usersData) {
          const formattedUsers = Object.keys(usersData)
            .filter((key) => key !== userId) // Exclude current user
            .map((key) => ({
              id: key,
              name: usersData[key].name,
              role: usersData[key].role,
            }));
          setAllUsers(formattedUsers);
        }
      });
    }
  }, [userId, isAdmin]);

  const handleSave = async () => {
    const db = getDatabase();
    const storage = getStorage();
    const userRef = ref(db, `users/${userId}`);

    let photoURL = photo;

    if (photo) {
      const timestamp = Date.now();
      const storageReference = storageRef(storage, `users/${userId}/profile_pic/${timestamp}`);
      await uploadBytes(storageReference, photo);
      photoURL = `gs://${storageReference.bucket}/${storageReference.fullPath}`;
    }

    const updatedData = {
      name: name,
      photo: photoURL, // Store the URL of the uploaded photo
      role: role === "admin" ? "admin" : "nutri", // Save as "admin" or "nutri"
    };

    update(userRef, updatedData)
      .then(() => {
        console.log("Profile updated successfully");
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000); // Hide message after 3 seconds
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file); // Store the file to be uploaded
  };

  const handleNewUserFileChange = (e) => {
    const file = e.target.files[0];
    setNewUserPhoto(file); // Store the file to be uploaded for new user
  };

  const handleEditUser = (id) => {
    setEditingOtherUser(true);
    navigate(`/editar-perfil/${id}`); // Navigate to the edit page for the selected user
  };

  const handleGoBack = () => {
    setEditingOtherUser(false);
    navigate(-1); // Go back to the previous page
  };

  const generatePassword = () => {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
    let password = "";
    for (let i = 0; i < 12; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  };

  const handleCreateUser = async () => {
    const auth = getAuth();
    const db = getDatabase();
    const storage = getStorage();
    const password = generatePassword();
    setNewUserPassword(password);
    setShowPassword(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, newUserEmail, password);
      const newUserId = userCredential.user.uid;

      let photoURL = null;
      if (newUserPhoto) {
        const timestamp = Date.now();
        const storageReference = storageRef(storage, `users/${newUserId}/profile_pic/${timestamp}`);
        await uploadBytes(storageReference, newUserPhoto);
        photoURL = `gs://${storageReference.bucket}/${storageReference.fullPath}`;
      }

      const newUser = {
        name: newUserName,
        role: newUserRole,
        photo: photoURL,
      };

      await update(ref(db, `users/${newUserId}`), newUser);

      console.log("New user created successfully");
      setNewUserName("");
      setNewUserEmail("");
      setNewUserRole("nutri");
      setNewUserPhoto(null);
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000); // Hide message after 3 seconds
    } catch (error) {
      console.error("Error creating new user:", error);
    }
  };

  return (
    <EditarPerfilRoot>
      <FunctionsHeader onLogoClick={() => navigate("/home")} />
      <Breadcrumb path={`Página inicial > Editar Perfil > ${name}`} />
      <MainContent>
        <ContentWrapper>
          {editingOtherUser && (
            <BackButton onClick={handleGoBack}>Voltar</BackButton>
          )}

          <Title>Editar Perfil</Title>
          <InputWrapper>
            <StyledInput
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nome"
            />
            <FileInput type="file" onChange={handleFileChange} />
            <RoleSelect value={role} onChange={(e) => setRole(e.target.value)}>
              <option value="nutri">Nutricionista</option>
              <option value="admin">Administrador</option>
            </RoleSelect>
          </InputWrapper>
          <ContinueButton onClick={handleSave}>Salvar Perfil</ContinueButton>
          <SuccessMessage show={showSuccessMessage}>Editado!</SuccessMessage>

          {isAdmin && (
            <AdminSection>
              <Title>Administração de Perfis</Title>
              <UserList>
                {allUsers.map((user) => (
                  <UserListItem key={user.id} onClick={() => handleEditUser(user.id)}>
                    <span>{user.name}</span>
                    <span>{user.role}</span>
                  </UserListItem>
                ))}
              </UserList>
              <NewUserForm>
                <Title>Criar novo usuário</Title>
                <StyledInput
                  type="text"
                  value={newUserName}
                  onChange={(e) => setNewUserName(e.target.value)}
                  placeholder="Nome"
                />
                <StyledInput
                  type="email"
                  value={newUserEmail}
                  onChange={(e) => setNewUserEmail(e.target.value)}
                  placeholder="Email"
                />
                <FileInput type="file" onChange={handleNewUserFileChange} />
                <RoleSelect value={newUserRole} onChange={(e) => setNewUserRole(e.target.value)}>
                  <option value="nutri">Nutricionista</option>
                  <option value="admin">Administrador</option>
                </RoleSelect>
                <NewUserButton onClick={handleCreateUser}>Criar Conta</NewUserButton>
                {showPassword && (
                  <SuccessMessage show={showPassword}>
                    Usuário criado com sucesso! A senha temporária é: {newUserPassword}
                  </SuccessMessage>
                )}
              </NewUserForm>
            </AdminSection>
          )}
        </ContentWrapper>
      </MainContent>
    </EditarPerfilRoot>
  );
};

export default EditarPerfil;
