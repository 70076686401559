import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { getDatabase, ref as dbRef, onValue, update } from "firebase/database";
import { FaPencilAlt, FaSave, FaTimes, FaStar } from "react-icons/fa";
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";

const ReceitaRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-200);
  font-family: var(--font-roboto);
  overflow-x: hidden;
  overflow-y: auto;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const RecipeTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RecipeTitle = styled.h1`
  font-size: 2rem;
  color: var(--color-white);
  display: flex;
  align-items: center;
`;

const EditInput = styled.input`
  font-size: 2rem;
  padding: 5px;
`;

const EditIcon = styled(FaPencilAlt)`
  margin-left: 10px;
  cursor: pointer;
`;

const SaveIcon = styled(FaSave)`
  margin-left: 10px;
  cursor: pointer;
`;

const CancelIcon = styled(FaTimes)`
  margin-left: 10px;
  cursor: pointer;
`;

const StarIcon = styled(FaStar)`
  color: gold;
  font-size: 1.5rem;
  margin-right: 10px;
`;

const RecipeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  width: 100%;
`;

const RecipeCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const RecipeImage = styled.img`
  width: 100%;
  max-width: 220px;
  height: 156px;
  border-radius: var(--br-5xs);
  object-fit: cover;
  border: 1px solid orange;
`;

const RecipeName = styled.div`
  margin-top: 10px;
  font-size: 1rem;
  color: var(--color-white);
`;

const CostSummary = styled.div`
  width: 100%;
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 20px;
  border-radius: var(--br-5xs);
  margin-top: 20px;
`;

const Receita = () => {
  const { recipeId } = useParams(); // Fetch the recipeId from URL params
  const [recipe, setRecipe] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newDescription, setNewDescription] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [ingredientsData, setIngredientsData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (recipeId) {
      const db = getDatabase();
      const recipeRef = dbRef(db, `recipes/${recipeId}`);

      onValue(recipeRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setRecipe(data);
          setNewDescription(data.description);
          fetchAllIngredients(data.ingredients);
        } else {
          console.error("Recipe not found");
        }
      });
    }
  }, [recipeId]);

  const normalizeString = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  };

  const fetchAllIngredients = (recipeIngredients) => {
    const db = getDatabase();
    const ingredientsRef = dbRef(db, `ingredients`);
  
    onValue(ingredientsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setIngredientsData(data);
        if (Array.isArray(recipeIngredients)) {
          calculateTotalCost(recipeIngredients, data);
        } else {
          console.error("Recipe ingredients is not an array");
        }
      } else {
        console.error("Ingredients not found");
      }
    });
  };

  const calculateTotalCost = (recipeIngredients, allIngredients) => {
    let totalCost = 0;

    recipeIngredients.forEach((recipeIngredient) => {
      const normalizedRecipeIngredientName = normalizeString(recipeIngredient.name);
      const ingredient = Object.values(allIngredients).find(
        (ing) => normalizeString(ing.name) === normalizedRecipeIngredientName || ing.id === recipeIngredient.id
      );

      if (ingredient && ingredient.price && ingredient.price.brl_current) {
        const priceData = ingredient.price.brl_current;
        const ingredientCost = (recipeIngredient.quantity_g_or_ml / priceData.weightGrams) * priceData.price;
        totalCost += ingredientCost;
      }
    });

    setTotalCost(totalCost);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    if (window.confirm("Tem certeza que deseja editar o nome da receita?")) {
      const db = getDatabase();
      const recipeRef = dbRef(db, `recipes/${recipeId}`);
      update(recipeRef, { description: newDescription })
        .then(() => {
          setRecipe((prev) => ({ ...prev, description: newDescription }));
          setIsEditing(false);
        })
        .catch((error) => {
          console.error("Error updating recipe description:", error);
        });
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setNewDescription(recipe.description);
  };

  return (
    <ReceitaRoot>
      <FunctionsHeader onLogoClick={() => navigate("/home")} />
      <Breadcrumb path={`Página inicial > Receitas > ${recipe?.description || "Carregando..."}`} />
      <MainContent>
        <ContentWrapper>
          <Header>
            {isEditing ? (
              <div>
                <EditInput
                  type="text"
                  value={newDescription}
                  onChange={(e) => setNewDescription(e.target.value)}
                />
                <SaveIcon onClick={handleSaveClick} />
                <CancelIcon onClick={handleCancelClick} />
              </div>
            ) : (
              <RecipeTitleWrapper>
                {recipe?.variants && <StarIcon />}
                <RecipeTitle>
                  {recipe?.description || "Frango a passarinho acebolado"}
                  <EditIcon onClick={handleEditClick} />
                </RecipeTitle>
              </RecipeTitleWrapper>
            )}
          </Header>
          <RecipeGrid>
            <RecipeCard onClick={() => navigate(`/cliente/${recipeId}`)}>
              <RecipeImage src={recipe?.image || "/default-recipe-image.webp"} alt={recipe?.description} />
              <RecipeName>Contém na dieta dos clientes</RecipeName>
            </RecipeCard>
            <RecipeCard onClick={() => navigate(`/criar-variante/${recipeId}`)}>
              <RecipeImage src={recipe?.image || "/default-recipe-image.webp"} alt={recipe?.description} />
              <RecipeName>Adicionar uma variante</RecipeName>
            </RecipeCard>
            <RecipeCard onClick={() => navigate(`/receita/${recipeId}/ingredientes`)}>
              <RecipeImage src={recipe?.image || "/default-recipe-image.webp"} alt={recipe?.description} />
              <RecipeName>Ingredientes</RecipeName>
            </RecipeCard>
            <RecipeCard onClick={() => navigate(`/ver-variantes/${recipeId}`)}>
              <RecipeImage src={recipe?.image || "/default-recipe-image.webp"} alt={recipe?.description} />
              <RecipeName>Ver variantes</RecipeName>
            </RecipeCard>
          </RecipeGrid>
          <CostSummary>
            <h2>Custo da Receita</h2>
            <p>Total: R$ {totalCost.toFixed(2).replace('.', ',')}</p>
          </CostSummary>
        </ContentWrapper>
      </MainContent>
    </ReceitaRoot>
  );
};

export default Receita;