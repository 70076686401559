import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, onValue } from "firebase/database";
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";
import { FaStar } from "react-icons/fa";

const ReceitaRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-200);
  font-family: var(--font-roboto);
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SearchBar = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border-radius: var(--br-5xs);
  overflow: hidden;
  height: 36px;
`;

const StyledAutocomplete = styled.div`
  flex: 1;
  .MuiInputBase-root {
    padding: 0;
    height: 36px;
    border: none;
    outline: none;
    font-size: 1rem;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiAutocomplete-inputRoot {
    padding: 8px 10px;
  }

  .MuiAutocomplete-option {
    background-color: var(--color-white) !important;
    color: var(--color-black) !important;
  }
`;

const SearchButton = styled.button`
  width: 36px;
  height: 36px;
  background-color: var(--color-darkorange);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ClientsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  width: 100%;
`;

const ClientCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

const ClientImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 220px;
  max-height: 156px;
  border-radius: var(--br-5xs);
  overflow: hidden;
`;

const ClientImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid orange;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StarIcon = styled(FaStar)`
  color: gold;
  font-size: 1.5rem;
`;

const ClientName = styled.div`
  margin-top: 10px;
  font-size: 1rem;
  color: var(--color-white);
`;

const Receita = () => {
  const [receitas, setReceitas] = useState([]);
  const [filteredReceitas, setFilteredReceitas] = useState([]);
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase();
    const receitasRef = ref(db, 'recipes'); // Adjust this to match your Firebase structure

    onValue(receitasRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const formattedReceitas = Object.keys(data).map(key => ({
          id: key,
          description: data[key].description,
          image: data[key].image || null, // Assume `image` holds the base64 data if available
          hasVariants: !!data[key].variants, // Check if the recipe has variants
        }));
        setReceitas(formattedReceitas);
        setFilteredReceitas(formattedReceitas);
      }
    });
  }, []);

  useEffect(() => {
    if (query.length > 2) {
      const filtered = receitas.filter(receita =>
        receita.description.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredReceitas(filtered);
    } else {
      setFilteredReceitas(receitas);
    }
  }, [query, receitas]);

  const onReceitaClick = useCallback((receitaPath) => {
    navigate(receitaPath);
  }, [navigate]);

  return (
    <ReceitaRoot>
      <FunctionsHeader
        onLogoClick={() => navigate("/home")}
        onClientClick={() => navigate("/clientes")}
        onIngredientClick={() => navigate("/receitas")}
      />
      <Breadcrumb path="Página inicial > receitas > Selecione um Receita" />
      <MainContent>
        <ContentWrapper>
          <SearchBar>
            <StyledAutocomplete>
              <input
                type="text"
                placeholder="Pesquise por Receita"
                onChange={(e) => setQuery(e.target.value)}
                style={{ width: "100%", height: "100%", border: "none", padding: "8px 10px" }}
              />
            </StyledAutocomplete>
            <SearchButton>
              <img src="/group-4.svg" alt="Search Icon" />
            </SearchButton>
          </SearchBar>
          <ClientsGrid>
            {filteredReceitas.map(receita => (
              <ClientCard key={receita.id} onClick={() => onReceitaClick(`/receita/${receita.id}`)}>
                <ClientImageWrapper>
                  <ClientImage
                    src={receita.image ? `data:image/png;base64,${receita.image}` : "/default-recipe-image.webp"}
                    alt={receita.description}
                  />
                  {receita.hasVariants && (
                    <Overlay>
                      <StarIcon />
                    </Overlay>
                  )}
                </ClientImageWrapper>
                <ClientName>{receita.description}</ClientName>
              </ClientCard>
            ))}
          </ClientsGrid>
        </ContentWrapper>
      </MainContent>
    </ReceitaRoot>
  );
};

export default Receita;