import React, { useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import FunctionsHeader from "../components/FunctionsHeader"; // Import the header component
import Breadcrumb from "../components/Breadcrumb"; // Import the breadcrumb component

const IngredientesRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-200);
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-whitesmoke);
  font-family: var(--small);
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const MenusParent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  width: 100%;
`;

const MenuItemContainer = styled.div`
  width: 100%;
  max-width: 300px;
  text-align: center;
  cursor: pointer;
`;

const MenuItem = styled.div`
  width: 100%;
  height: 200px;
  background-color: var(--color-black);
  border-bottom: 2px solid var(--color-orange);
  border-radius: var(--br-base);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
`;

const MenuItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--br-base);
`;

const MenuItemIcon = styled.img`
  width: 50px;
  height: 50px;
`;

const MenuItemText = styled.div`
  color: var(--color-white);
  font-size: 1rem;
  margin-top: 10px;
`;

const Ingredientes = () => {
  const navigate = useNavigate();

  const onMenusContainerClick = useCallback(() => {
    navigate("/busque-ou-pesquise-por-um-ingrediente");
  }, [navigate]);

  const onAddIngredientClick = useCallback(() => {
    navigate("/criar-ingrediente");
  }, [navigate]);

  return (
    <IngredientesRoot>
      <FunctionsHeader
        onLogoClick={() => navigate("/home")}
        onClientClick={() => navigate("/selecione-um-cliente")}
        onIngredientClick={onMenusContainerClick}
      />
      <Breadcrumb path="Página inicial > Ingredientes" />
      <MainContent>
        <MenusParent>
          <MenuItemContainer onClick={onMenusContainerClick}>
            <MenuItem>
              <MenuItemImage src="/rectangle-156@2x.png" alt="Ingredientes" />
            </MenuItem>
            <MenuItemText>Ingredientes</MenuItemText>
          </MenuItemContainer>
          <MenuItemContainer onClick={onAddIngredientClick}>
            <MenuItem>
              <MenuItemIcon src="/icbaselinepersonaddalt1.svg" alt="Criar um ingrediente" />
            </MenuItem>
            <MenuItemText>Criar um ingrediente</MenuItemText>
          </MenuItemContainer>
        </MenusParent>
      </MainContent>
    </IngredientesRoot>
  );
};

export default Ingredientes;
