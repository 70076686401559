import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import { getDatabase, ref, onValue } from "firebase/database";
import { Menu, MenuItem, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate } from 'react-router-dom';

// Styled components for the search input and button
const StyledAutocomplete = styled(Autocomplete)`
  flex: 1;
  .MuiInputBase-root {
    padding: 0; /* Remove default padding */
    height: 36px; /* Match the height of the button */
    border: none;
    outline: none;
    font-size: 1rem;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none; /* Remove the default border */
  }

  .MuiAutocomplete-inputRoot {
    padding: 8px 10px; /* Match the padding of SearchInput */
  }
`;

const SearchButton = styled.button`
  width: 36px;
  height: 36px;
  background-color: var(--color-darkorange);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const FilterButton = styled(IconButton)`
  padding: 0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GlobalSearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%; /* Ensures the container takes full width */
  height: 36px; /* Matches the height of input and buttons */
`;

const GlobalSearch = () => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [allData, setAllData] = useState([]);
  const [filters, setFilters] = useState({
    Ingrediente: true,
    Cliente: true,
    Receita: true,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase();

    const ingredientsRef = ref(db, 'ingredients');
    const customersRef = ref(db, 'customers');
    const recipesRef = ref(db, 'recipes');

    // Fetch data function
    const fetchData = () => {
      // Fetch ingredients
      onValue(ingredientsRef, (snapshot) => {
        const ingredients = snapshot.val();
        const ingredientsData = ingredients ? Object.keys(ingredients).map(key => ({
          type: 'Ingrediente',
          name: ingredients[key]?.name || '',
          id: key
        })) : [];
        setAllData(prevData => [...prevData, ...ingredientsData]);
      });

      // Fetch customers
      onValue(customersRef, (snapshot) => {
        const customers = snapshot.val();
        const customersData = customers ? Object.keys(customers).map(key => ({
          type: 'Cliente',
          name: customers[key]?.name || '',
          id: key
        })) : [];
        setAllData(prevData => [...prevData, ...customersData]);
      });

      // Fetch recipes
      onValue(recipesRef, (snapshot) => {
        const recipes = snapshot.val();
        const recipesData = recipes ? Object.keys(recipes).map(key => ({
          type: 'Receita',
          name: recipes[key]?.description || '', // Adjusted to fetch 'description' as 'name'
          id: key
        })) : [];
        setAllData(prevData => [...prevData, ...recipesData]);
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (query.length > 2) {
      // Filter based on selected types
      const filteredData = allData.filter(item => filters[item.type]);

      // Remove duplicates based on the name field only
      const uniqueNames = new Set();
      const filteredSuggestions = [];

      filteredData.forEach(item => {
        if (!uniqueNames.has(item.name.toLowerCase())) {
          uniqueNames.add(item.name.toLowerCase());
          filteredSuggestions.push(item);
        }
      });

      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [query, allData, filters]);

  const handleFilterChange = (type) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [type]: !prevFilters[type]
    }));
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (event, value) => {
    if (value) {
      const { type, id } = value;
      if (type === 'Receita') {
        navigate(`/receita/${id}`);
      } else if (type === 'Ingrediente') {
        navigate(`/ingrediente/${id}`);
      } else if (type === 'Cliente') {
        navigate(`/cliente/${id}`);
      }
    }
  };

  return (
    <GlobalSearchContainer>
      <StyledAutocomplete
        freeSolo
        options={suggestions}
        getOptionLabel={(option) => `${option.name} (${option.type})`}
        onInputChange={(event, newInputValue) => {
          setQuery(newInputValue);
        }}
        onChange={handleOptionSelect}
        renderInput={(params) => (
          <TextField 
            {...params} 
            variant="outlined" 
            fullWidth
            placeholder="Pesquise por: Ingredientes, Receitas ou Clientes"
          />
        )}
      />
      <FilterButton onClick={handleFilterClick}>
        <FilterListIcon />
      </FilterButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleFilterClose}
      >
        {['Ingrediente', 'Cliente', 'Receita'].map(type => (
          <MenuItem key={type}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters[type]}
                  onChange={() => handleFilterChange(type)}
                />
              }
              label={type}
            />
          </MenuItem>
        ))}
      </Menu>
    </GlobalSearchContainer>
  );
};

export default GlobalSearch;
