import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { getDatabase, ref as dbRef, onValue, remove, set, push } from "firebase/database";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";
import SelecionarReceitasModal from "../components/SelecionarReceitasModal";

const PerfilDoClienteRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-200);
  font-family: var(--font-roboto);
  overflow-x: hidden;
  overflow-y: auto; /* Page scrolls if necessary */
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const HeroSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; /* Reduced to avoid scroll */
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ClientImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
`;

const ClientImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 20px;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  border: 2px solid var(--color-darkorange);
  font-size: 0.9rem;
  cursor: pointer;
  text-align: center;
  min-width: 180px;

  &:hover {
    background-color: var(--color-darkorange);
    color: var(--color-white);
  }
`;

const SummarySection = styled.div`
  flex: 1;
  background-color: var(--color-black);
  padding: 20px;
  border-radius: var(--br-5xs);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 300px;
  margin-right: 20px; /* Added to ensure space on large screens */
  height: 100px;
`;

const SummaryDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SummaryLabel = styled.span`
  font-size: 1.1rem;
`;

const SummaryValue = styled.span`
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--color-darkorange);
  margin-right: 20px; /* Added right margin for spacing */
`;

const ProfitValue = styled(SummaryValue)`
  color: ${props => (props.profit >= 0 ? 'var(--color-green)' : 'var(--color-red)')};
`;

const DietTitle = styled.h2`
  font-size: 1.5rem;
  color: var(--color-white);
  text-align: center;
  margin-bottom: 20px;
`;

const DietSection = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: var(--br-5xs);
  color: var(--color-white);
  background-color: var(--color-black);
  max-height: fit-content; /* Adjust to content size */
`;

const DietItemsWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  overflow: visible; /* Prevent internal scrolling */
`;

const DietItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  max-width: 157px;
  position: relative;

  &.removal-mode {
    background-color: gray;
    pointer-events: none;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const DietImage = styled.img`
  width: 157px;
  height: 157px;
  border: 1px solid orange; /* Added border */
  border-radius: var(--br-5xs);
  object-fit: cover;
  margin-bottom: 10px;
`;

const RemoveIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--color-darkorange);
  color: var(--color-white);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.2rem;
  visibility: hidden;

  &.removal-mode {
    visibility: visible;
    pointer-events: auto;
  }
`;

const EditIcon = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: var(--color-darkorange);
  color: var(--color-white);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.2rem;

  &:hover {
    background-color: var(--color-orange);
  }
`;

const AddDietItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

const AddDietButton = styled.button`
  width: 157px;
  height: 157px;
  border-radius: 50%;
  border: none;
  background-color: var(--color-darkorange);
  color: var(--color-white);
  font-size: 2rem;
  cursor: pointer;

  &:hover {
    background-color: var(--color-orange);
  }
`;

const PerfilDoCliente = () => {
  const { id } = useParams(); // Get the id from the URL
  const navigate = useNavigate();
  const [clientData, setClientData] = useState(null);
  const [profilePicUrl, setProfilePicUrl] = useState('');
  const [recipes, setRecipes] = useState([]);
  const [diets, setDiets] = useState([]);
  const [selectedDiet, setSelectedDiet] = useState(null);
  const [removalMode, setRemovalMode] = useState(false);
  const [showAddRecipeModal, setShowAddRecipeModal] = useState(false); // State to control modal visibility
  const [isAdmin, setIsAdmin] = useState(false);
  const [marginPercent, setMarginPercent] = useState(20);

  const normalizeString = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  };
  
  const calculateTotalCost = (recipeIngredients, allIngredients) => {
    let totalCost = 0;
  
    recipeIngredients.forEach((recipeIngredient) => {
      const normalizedRecipeIngredientName = normalizeString(recipeIngredient.name);
      const ingredient = Object.values(allIngredients).find(
        (ing) => normalizeString(ing.name) === normalizedRecipeIngredientName || ing.id === recipeIngredient.id
      );
  
      if (ingredient && ingredient.price && ingredient.price.brl_current) {
        const priceData = ingredient.price.brl_current;
        const ingredientCost = (recipeIngredient.quantity_g_or_ml / priceData.weightGrams) * priceData.price;
        totalCost += ingredientCost;
      }
    });
  
    return totalCost;
  };

  const formatPrice = (price) => {
    return price.toFixed(2).replace('.', ',');
  };

  const calculateDietSummary = (diet, allIngredients, marginPercent) => {
    let cost = 0;
    let profit = 0;
  
    diet.recipes.forEach(recipe => {
      const recipeCost = calculateTotalCost(recipe.ingredients, allIngredients);
      cost += recipeCost;
      profit += (recipe.price || 0) - recipeCost;
    });
  
    const revenue = cost * (1 + marginPercent / 100); // Use the marginPercent
    return {
      cost,
      revenue,
      profit: revenue - cost
    };
  };

  useEffect(() => {
    if (id) {
      const db = getDatabase();
      const clientRef = dbRef(db, `customers/${id}`);
  
      onValue(clientRef, (snapshot) => {
        const data = snapshot.val();
        setClientData(data);
  
        if (data && data.recipes) {
          const dietPromises = Object.keys(data.recipes).map(dietId => {
            const diet = data.recipes[dietId];
  
            const recipePromises = (diet.recipes ? Object.keys(diet.recipes) : []).map(recipeId => {
              return new Promise((resolve) => {
                const recipeRef = dbRef(db, `recipes/${recipeId}`);
                onValue(recipeRef, (recipeSnapshot) => {
                  resolve({ id: recipeId, ...recipeSnapshot.val() });
                });
              });
            });
  
            return Promise.all(recipePromises).then(recipes => ({
              dietName: diet.diet_name,
              recipes,
              marginPercent: diet.margin_percent || 20 // Default to 20% if not set
            }));
          });
  
          Promise.all(dietPromises).then(diets => {
            setDiets(diets);
  
            // Fetch all ingredients data
            const ingredientsRef = dbRef(db, `ingredients`);
            onValue(ingredientsRef, (snapshot) => {
              const allIngredients = snapshot.val();
  
              // Calculate summary for each diet
              const updatedDiets = diets.map(diet => {
                const summary = calculateDietSummary(diet, allIngredients, diet.marginPercent);
                return {
                  ...diet,
                  summary
                };
              });
  
              setDiets(updatedDiets);
            });
          });
        }
      });
    }
  }, [id]);

  const handleMarginChange = (dietIndex, newMargin) => {
    const db = getDatabase();
    const dietId = Object.keys(clientData.recipes)[dietIndex];
    const dietRef = dbRef(db, `customers/${id}/recipes/${dietId}`);
    set(dietRef, { ...clientData.recipes[dietId], margin_percent: newMargin })
      .then(() => {
        const updatedDiets = diets.map((diet, index) => {
          if (index === dietIndex) {
            const updatedDiet = { ...diet, marginPercent: newMargin };
            const summary = calculateDietSummary(updatedDiet, allIngredients, newMargin);
            return { ...updatedDiet, summary };
          }
          return diet;
        });
        setDiets(updatedDiets);
      })
      .catch((error) => console.error("Error updating margin:", error));
  };
  

  const handleUserDataFetched = useCallback((userData) => {
    console.log(userData)
    userData.role === "admin" ? setIsAdmin(true) : setIsAdmin(false); // Assuming userData has an isAdmin property
  }, []);

  const onFrameContainerClick = () => {
    navigate(`/cliente/${id}/arquivos`);
  };

  const handleDietClick = (dietIndex) => {
    if (!removalMode) {
      setSelectedDiet(dietIndex);
    }
  };

  const handleRecipeClick = (recipeId) => {
    if (!removalMode) {
      navigate(`/receita/${recipeId}`);
    }
  };

  const handleBackClick = () => {
    setSelectedDiet(null);
  };

  const toggleRemovalMode = () => {
    setRemovalMode(!removalMode);
  };

  const handleRemoveDiet = (dietIndex) => {
    if (window.confirm("Tem certeza que deseja remover esta cardápio? A remoção é definitiva.")) {
      const db = getDatabase();
      const dietId = Object.keys(clientData.recipes)[dietIndex];
      const clientRef = dbRef(db, `customers/${id}/recipes/${dietId}`);
      remove(clientRef).then(() => {
        setDiets(diets.filter((_, index) => index !== dietIndex));
        setRemovalMode(false);
      }).catch((error) => console.error("Error removing diet:", error));
    }
  };

  const handleRemoveRecipe = (recipeId) => {
    if (window.confirm("Tem certeza que deseja remover esta receita? A remoção é definitiva.")) {
      const db = getDatabase();
      const dietId = Object.keys(clientData.recipes)[selectedDiet];
      const recipeRef = dbRef(db, `customers/${id}/recipes/${dietId}/recipes/${recipeId}`);
      remove(recipeRef).then(() => {
        setDiets(diets.map((diet, index) => {
          if (index === selectedDiet) {
            return {
              ...diet,
              recipes: diet.recipes.filter(recipe => recipe.id !== recipeId)
            };
          }
          return diet;
        }));
        setRemovalMode(false);
      }).catch((error) => console.error("Error removing recipe:", error));
    }
  };

  const handleAddDiet = () => {
    const dietName = prompt("Digite o nome da nova cardápio:");
    if (dietName) {
      const db = getDatabase();
      const dietsRef = dbRef(db, `customers/${id}/recipes`);
  
      // Obtém as cardápios existentes e usa a contagem para adicionar a nova cardápio corretamente
      onValue(dietsRef, (snapshot) => {
        const existingDiets = snapshot.val() || {};
        const dietKeys = Object.keys(existingDiets); // Obtém as chaves das cardápios existentes
        const newDietId = dietKeys.length; // Usa a contagem de chaves como novo ID
  
        // Adiciona a nova cardápio com a chave apropriada
        const newDietRef = dbRef(db, `customers/${id}/recipes/${newDietId}`);
        set(newDietRef, { diet_name: dietName, recipes: {} })
          .then(() => {
            setDiets([...diets, { dietName, recipes: [] }]);
          })
          .catch((error) => console.error("Error adding diet:", error));
      }, {
        onlyOnce: true // Pega os dados uma vez e não continua ouvindo mudanças
      });
    }
  };
  
  const handleEditDiet = (dietIndex) => {
    const newDietName = prompt("Digite o novo nome do cardápio:");
    if (newDietName) {
      const db = getDatabase();
      const dietId = Object.keys(clientData.recipes)[dietIndex];
      const dietRef = dbRef(db, `customers/${id}/recipes/${dietId}`);
      set(dietRef, { ...clientData.recipes[dietId], diet_name: newDietName })
        .then(() => {
          setDiets(diets.map((diet, index) => {
            if (index === dietIndex) {
              return { ...diet, dietName: newDietName };
            }
            return diet;
          }));
        })
        .catch((error) => console.error("Error editing diet:", error));
    }
  };

  const handleAddRecipe = () => {
    setShowAddRecipeModal(true); // Show the modal when the button is clicked
  };

  const handleCloseModal = () => {
    setShowAddRecipeModal(false); // Close the modal
  };

  const BackButton = styled.button`
    background: none;
    border: none;
    color: var(--color-darkorange);
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 20px;
    text-decoration: underline;

    &:hover {
      color: var(--color-orange);
    }
  `;

  const SummaryValue = styled.span`
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--color-darkorange);
  margin-right: 20px; /* Added right margin for spacing */
`;

const EditableSummaryValue = styled(SummaryValue)`
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: var(--color-orange);
  }
`;

  if (!clientData) {
    return <div>Loading...</div>; // Add a loading state
  }

  return (
    <PerfilDoClienteRoot>
      <FunctionsHeader onLogoClick={() => navigate("/home")} onUserDataFetched={handleUserDataFetched} />
      <Breadcrumb path={`Página inicial > Clientes > ${clientData.name || 'Cliente'}`} />
      <MainContent>
        <ContentWrapper>
          <DietTitle>{clientData.name}</DietTitle> {/* Updated Title */}
          <HeroSection>
            <ClientImageWrapper>
              <ClientImage
                src={profilePicUrl || "/default-avatar.png"}
                alt={clientData.name}
              />
            </ClientImageWrapper>
            <ActionButtons>
              <ActionButton onClick={onFrameContainerClick}>Arquivos do cliente</ActionButton>
              <ActionButton onClick={toggleRemovalMode}>
                {removalMode ? "Cancelar a remoção" : selectedDiet === null ? "Remover cardápio" : "Remover receita"}
              </ActionButton>
              <ActionButton>Imprimir dados do cliente</ActionButton>
            </ActionButtons>
            <SummarySection>
              {selectedDiet !== null && isAdmin ? (
                <>
                  <SummaryDetail>
                  <SummaryLabel>Margem da dieta:</SummaryLabel>
                  <EditableSummaryValue onClick={() => {
                    const newMargin = prompt("Digite a nova margem da dieta (%):", diets[selectedDiet].marginPercent);
                    if (newMargin !== null) {
                      handleMarginChange(selectedDiet, parseFloat(newMargin));
                    }
                  }}>
                    {diets[selectedDiet].marginPercent}%
                  </EditableSummaryValue>
                </SummaryDetail>
                  <SummaryDetail>
                    <SummaryLabel>Custo total da dieta:</SummaryLabel>
                    <SummaryValue>R$: {formatPrice(diets[selectedDiet].summary.cost)}</SummaryValue>
                  </SummaryDetail>
                  <SummaryDetail>
                    <SummaryLabel>Receita total da dieta:</SummaryLabel>
                    <SummaryValue>R$: {formatPrice(diets[selectedDiet].summary.revenue)}</SummaryValue>
                  </SummaryDetail>
                  <SummaryDetail>
                    <SummaryLabel>Lucro total com o cliente:</SummaryLabel>
                    <ProfitValue profit={diets[selectedDiet].summary.profit}>R$: {formatPrice(diets[selectedDiet].summary.profit)}</ProfitValue>
                  </SummaryDetail>
                </>
              ) : null}
            </SummarySection>
          </HeroSection>
          <DietTitle>Resumo do cliente</DietTitle>
          <DietSection>
            <DietItemsWrapper>
              {selectedDiet === null ? (
                <>
                  {diets.map((diet, dietIndex) => (
                    <DietItem key={dietIndex} onClick={() => handleDietClick(dietIndex)} className={removalMode ? "removal-mode" : ""}>
                      <DietImage src="/bento_soul_ben.svg" alt={diet.dietName} /> {/* Updated image */}
                      <span>{diet.dietName}</span>
                      <RemoveIcon onClick={(e) => { e.stopPropagation(); handleRemoveDiet(dietIndex); }} className={removalMode ? "removal-mode" : ""}>-</RemoveIcon>
                      <EditIcon onClick={(e) => { e.stopPropagation(); handleEditDiet(dietIndex); }}>✎</EditIcon>
                    </DietItem>
                  ))}
                  <AddDietItem>
                    <AddDietButton onClick={handleAddDiet}>+</AddDietButton>
                    <span>Adicionar um cardápio</span> {/* Updated text */}
                  </AddDietItem>
                </>
              ) : (
                <>
                  <BackButton onClick={handleBackClick}>Voltar</BackButton>
                  {diets[selectedDiet].recipes.map((recipe) => (
                    <DietItem key={recipe.id} onClick={() => handleRecipeClick(recipe.id)} className={removalMode ? "removal-mode" : ""}>
                      <DietImage src={recipe.image || "/default-recipe-image.webp"} alt={recipe.description} />
                      <span>{recipe.description}</span>
                      <RemoveIcon onClick={(e) => { e.stopPropagation(); handleRemoveRecipe(recipe.id); }} className={removalMode ? "removal-mode" : ""}>-</RemoveIcon>
                    </DietItem>
                  ))}
                  <AddDietItem>
                    <AddDietButton onClick={handleAddRecipe}>+</AddDietButton>
                    <span>Adicionar receita(s)</span> {/* Updated text */}
                  </AddDietItem>
                </>
              )}
            </DietItemsWrapper>
          </DietSection>
        </ContentWrapper>
      </MainContent>
      {showAddRecipeModal && (
        <SelecionarReceitasModal
          onClose={handleCloseModal}
          onSave={(selectedRecipes) => {
            const db = getDatabase();
            const dietId = Object.keys(clientData.recipes)[selectedDiet];
            const updates = {};
            selectedRecipes.forEach((recipe) => {
              updates[recipe.id] = { description: recipe.description }; // Use original recipe ID
            });
            set(dbRef(db, `customers/${id}/recipes/${dietId}/recipes`), updates).then(() => {
              setDiets(diets.map((diet, index) => {
                if (index === selectedDiet) {
                  return {
                    ...diet,
                    recipes: [...diet.recipes, ...selectedRecipes.map(recipe => ({ id: recipe.id, description: recipe.description }))]
                  };
                }
                return diet;
              }));
              setShowAddRecipeModal(false);
            }).catch((error) => console.error("Error adding recipes:", error));
          }}
        />
      )}
    </PerfilDoClienteRoot>
  );
}

export default PerfilDoCliente;
