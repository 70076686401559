import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { getDatabase, ref as dbRef, onValue, set, push } from "firebase/database";
import { getStorage, ref as storageRef, getDownloadURL, uploadBytes } from "firebase/storage";
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";

const PerfilIngredienteRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-200);
  font-family: var(--font-roboto);
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 20px; /* Adiciona margem à direita */
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const HeroSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: left;
  }
`;

const IngredientInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
`;

const IngredientTitle = styled.h2`
  font-size: 1.5rem;
  color: var(--color-white);
  text-align: left;
  margin-bottom: 20px; /* Increased margin-bottom for spacing */
`;

const IngredientImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  position: relative;

  &:hover::after {
    content: "Alterar imagem";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

const IngredientImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 20px;
  
  @media (max-width: 768px) {
    align-items: center;
  }
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  border: 2px solid var(--color-darkorange);
  font-size: 0.9rem;
  cursor: pointer;
  text-align: center;
  min-width: 180px;

  &:hover {
    background-color: var(--color-darkorange);
    color: var(--color-white);
  }
`;

const RecipesWrapper = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: var(--br-5xs);
  color: var(--color-white);
  background-color: var(--color-black);
  max-height: fit-content;
`;

const RecipesTitle = styled.h2`
  font-size: 1.5rem;
  color: var(--color-white);
  text-align: center;
  margin-bottom: 20px;
`;

const RecipesGrid = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  overflow: visible;
`;

const RecipeCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  max-width: 157px;

  &:hover {
    opacity: 0.8;
  }
`;

const RecipeImage = styled.img`
  width: 157px;
  height: 157px;
  border: 1px solid orange;
  border-radius: var(--br-5xs);
  object-fit: cover;
  margin-bottom: 10px;
`;

const AddRecipeCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

const AddRecipeButton = styled.button`
  width: 157px;
  height: 157px;
  border-radius: 50%;
  border: none;
  background-color: var(--color-darkorange);
  color: var(--color-white);
  font-size: 2rem;
  cursor: pointer;

  &:hover {
    background-color: var(--color-orange);
  }
`;

const SummaryTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid var(--color-white);
  }

  tr:nth-child(even) {
    border-bottom: 1px solid var(--color-orange);
  }

  td {
    padding: 10px;
  }
`;

const IngredientSummary = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: var(--br-5xs);
  color: var(--color-white);
  background-color: var(--color-black);
  max-height: fit-content;
  margin-left: 20px;
  flex: 1;
`;

const PricingForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px; /* Aumenta o espaço entre os elementos */
  padding: 20px;
  background-color: var(--color-black);
  color: var(--color-white);
  border-radius: var(--br-5xs);
  align-items: flex-end; /* Alinha as caixas de entrada no final do espaço */
`;

const PricingInput = styled.input`
  padding: 10px;
  border-radius: var(--br-5xs);
  border: 1px solid var(--color-white);
  background-color: var(--color-gray-200);
  color: var(--color-white); /* Ajusta o texto de entrada para ser branco */
  width: 100px; /* Padroniza o tamanho para entrada de um número */
  margin-left: 10px; /* Adiciona margem entre o texto e a caixa de entrada */
`;

const PriceDisplay = styled.div`
  font-size: 1.2rem; /* Aumenta o tamanho da fonte para destaque */
  font-weight: bold; /* Adiciona negrito para destaque */
  color: var(--color-darkorange); /* Define a cor para destaque */
  margin-left: 10px; /* Adiciona margem entre o texto e o valor */
  display: inline-block; /* Garante que esteja em linha com a label */
`;

const SaveButton = styled.button`
  padding: 10px;
  border-radius: var(--br-5xs);
  background-color: var(--color-darkorange);
  color: var(--color-white);
  border: none;
  cursor: pointer;

  &:hover {
    background-color: var(--color-orange);
  }
`;

const ActiveButton = styled.button`
  background-color: var(--color-darkorange);
  color: var(--color-white);
  padding: 10px;
  border-radius: var(--br-5xs);
  border: none;
  cursor: pointer;

  &:hover {
    background-color: var(--color-orange);
  }
`;

const NutritionalTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th, td {
    padding: 10px;
    border: 1px solid var(--color-gray-300);
    text-align: left; /* Alinha os títulos à esquerda */
  }

  th {
    background-color: var(--color-gray-100);
  }
`;

const PerfilIngrediente = () => {
  const { id } = useParams(); // Get the id from the URL
  const navigate = useNavigate();
  const [ingredientData, setIngredientData] = useState(null);
  const [ingredientPicUrl, setIngredientPicUrl] = useState('');
  const [recipes, setRecipes] = useState([]);
  const [summaryContent, setSummaryContent] = useState("resumo");
  const [weightGrams, setweightGrams] = useState('');
  const [price, setprice] = useState('');
  const [nutritionalValues, setNutritionalValues] = useState(null);

  useEffect(() => {
    if (id) {
      const db = getDatabase();
      const ingredientRef = dbRef(db, `ingredients/${id}`);

      onValue(ingredientRef, (snapshot) => {
        const data = snapshot.val();
        setIngredientData(data);
        setNutritionalValues(data.nutritionalValues);

        // Fetch ingredient picture from Firebase Storage
        if (data.image) {
          const storage = getStorage();
          const picRef = storageRef(storage, data.image);
          getDownloadURL(picRef)
            .then((url) => setIngredientPicUrl(url))
            .catch((error) => console.error("Error fetching ingredient pic:", error));
        }

        // Fetch recipes that contain this ingredient
        const recipesRef = dbRef(db, 'recipes');
        onValue(recipesRef, (recipesSnapshot) => {
          const recipesData = recipesSnapshot.val();
          const filteredRecipes = Object.entries(recipesData).filter(([recipeId, recipe]) => {
            return recipe.ingredients && Object.values(recipe.ingredients).some(ingredient => ingredient.id === id || ingredient.name === data.name);
          }).map(([recipeId, recipe]) => ({ id: recipeId, ...recipe }));
          setRecipes(filteredRecipes);
        });
      });
    }
  }, [id]);

  const handleRecipeClick = (recipeId) => {
    navigate(`/receita/${recipeId}`);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const storage = getStorage();
      const picRef = storageRef(storage, `ingredients/picture/${id}`);
      uploadBytes(picRef, file).then(() => {
        getDownloadURL(picRef)
          .then((url) => setIngredientPicUrl(url))
          .catch((error) => console.error("Error fetching new ingredient pic:", error));
      }).catch((error) => console.error("Error uploading new ingredient pic:", error));
    }
  };

  const triggerFileInput = () => {
    document.getElementById('fileInput').click();
  };

  const formatPrice = (value) => {
    return value.replace('.', ',');
  };

  const handlepriceChange = (e) => {
    const formattedValue = e.target.value.replace(',', '.');
    setprice(formattedValue);
  };

  const handleSavePrice = () => {
    const db = getDatabase();
    const ingredientRef = dbRef(db, `ingredients/${id}/price`);
    const timestamp = Date.now();

    set(dbRef(db, `ingredients/${id}/price/brl_current`), {
      price,
      weightGrams,
      price: parseFloat(price).toFixed(2) // Salva com separador decimal
    });

    push(dbRef(db, `ingredients/${id}/price/brl_history`), {
      timestamp,
      price,
      weightGrams,
      price: parseFloat(price).toFixed(2) // Salva com separador decimal
    });
  };

  const calculateSummary = () => {
    if (!recipes.length) return {
      usedInRecipes: 'n/a',
      minQuantityRecipe: 'n/a',
      maxQuantityRecipe: 'n/a',
      totalUsage: 'n/a',
      totalCost: 'n/a'
    };
  
    let totalUsage = 0;
    let minUsage = Infinity;
    let maxUsage = -Infinity;
    let minRecipe = "";
    let maxRecipe = "";
    let totalCost = 0;
  
    recipes.forEach(recipe => {
      const ingredient = Object.values(recipe.ingredients).find(ing => ing.id === id || ing.name === ingredientData.name);
      if (ingredient) {
        const usage = parseFloat(ingredient.quantity_g_or_ml);
        if (!isNaN(usage)) {
          totalUsage += usage;
  
          if (usage < minUsage) {
            minUsage = usage;
            minRecipe = recipe.description;
          }
  
          if (usage > maxUsage) {
            maxUsage = usage;
            maxRecipe = recipe.description;
          }
  
          if (ingredientData.price?.brl_current?.price) {
            totalCost += (usage / 1000) * ingredientData.price.brl_current.price;
          }
        }
      }
    });
  
    // Ensure totalUsage and totalCost are numbers before calling toFixed
    const formattedTotalUsage = typeof totalUsage === 'number' && !isNaN(totalUsage) ? `${totalUsage.toFixed(2).replace('.', ',')} g` : 'n/a';
    const formattedTotalCost = typeof totalCost === 'number' && !isNaN(totalCost) ? `R$ ${totalCost.toFixed(2).replace('.', ',')}` : 'n/a';
  
    return {
      usedInRecipes: recipes.length || 'n/a',
      minQuantityRecipe: minRecipe || 'n/a',
      maxQuantityRecipe: maxRecipe || 'n/a',
      totalUsage: formattedTotalUsage,
      totalCost: formattedTotalCost
    };
  };

  const renderSummaryContent = () => {
    const summary = calculateSummary();

    if (summaryContent === "resumo") {
      return (
        <SummaryTable>
          <tbody>
            <tr>
              <td>Utilizado em receitas</td>
              <td>{summary.usedInRecipes}</td>
            </tr>
            <tr>
              <td>Aparece em maior quantidade na receita</td>
              <td>{summary.maxQuantityRecipe}</td>
            </tr>
            <tr>
              <td>Aparece em menor quantidade na receita</td>
              <td>{summary.minQuantityRecipe}</td>
            </tr>
            <tr>
              <td>Total de utilização em receitas</td> {/* Substitui "Média de utilização em receitas" */}
              <td>{summary.totalUsage}</td> {/* Ajusta para total de utilização */}
            </tr>
            <tr>
              <td>Custo com o ingrediente</td>
              <td>{summary.totalCost}</td> {/* Calcula o custo corretamente */}
            </tr>
          </tbody>
        </SummaryTable>
      );
    } else if (summaryContent === "precificacao") {
      return (
        <div style={{ display: 'flex', gap: '20px' }}>
          <SummaryTable style={{ flex: 1 }}>
            <thead>
              <tr>
                <th colSpan="2">Histórico de Precificação</th>
              </tr>
              <tr>
                <th>Data</th>
                <th>Preço</th>
              </tr>
            </thead>
            <tbody>
              {ingredientData.price?.brl_history && Object.entries(ingredientData.price.brl_history).map(([key, value]) => (
                <tr key={key}>
                  <td>{new Date(value.timestamp).toLocaleDateString()}</td>
                  <td>{formatPrice(value.price)}</td> {/* Formata o valor com vírgula */}
                </tr>
              ))}
            </tbody>
          </SummaryTable>
          <PricingForm>
            <label style={{ display: 'flex', alignItems: 'center' }}>
              Preço do Kg ou Litro
              <PriceDisplay>R$ {formatPrice(ingredientData.price?.brl_current?.price || 'N/A')}</PriceDisplay> {/* Exibe o preço atual com destaque e formatação */}
            </label>
            <label style={{ display: 'flex', alignItems: 'center' }}>
              Peso da unidade na embalagem (g ou ml)
              <PricingInput type="number" value={weightGrams} onChange={(e) => setweightGrams(e.target.value)} />
            </label>
            <label style={{ display: 'flex', alignItems: 'center' }}>
              Preço da unidade em R$
              <PricingInput type="text" value={formatPrice(price)} onChange={handlepriceChange} /> {/* Formata a entrada */}
            </label>
            <SaveButton onClick={handleSavePrice}>Salvar</SaveButton>
          </PricingForm>
        </div>
      );
    } else if (summaryContent === "informacao-nutricional") {
      return (
        <NutritionalTable>
          <thead>
            <tr>
              <th colSpan="4" className="sub-header">Informação Nutricional/Porção de 100g</th>
            </tr>
            <tr>
              <th>Componente</th>
              <th>Quantidade</th>
              <th>% VD*</th>
            </tr>
          </thead>
          <tbody>
          {nutritionalValues ? (
              <>
                <tr>
                  <td>Valor energético (kcal)</td>
                  <td>{nutritionalValues.energyKcal_kcal ? nutritionalValues.energyKcal_kcal.toFixed(1).replace('.', ',') : '0'}</td>
                  <td>{nutritionalValues.energyKcal_kcal ? Math.round((nutritionalValues.energyKcal_kcal / 2000) * 100) + '%' : '0'}</td>
                </tr>
                <tr>
                  <td>Carboidratos totais (g)</td>
                  <td>{nutritionalValues.carbohydrates_g?.total_g ? nutritionalValues.carbohydrates_g.total_g.toFixed(1).replace('.', ',') : '0'}</td>
                  <td>{nutritionalValues.carbohydrates_g?.total_g ? Math.round((nutritionalValues.carbohydrates_g.total_g / 300) * 100) + '%' : '0'}</td>
                </tr>
                <tr>
                  <td>Açúcares totais (g)</td>
                  <td>{nutritionalValues.carbohydrates_g?.sugars?.total_g ? nutritionalValues.carbohydrates_g.sugars.total_g.toFixed(1).replace('.', ',') : '0'}</td>
                  <td>{nutritionalValues.carbohydrates_g?.sugars?.total_g ? Math.round((nutritionalValues.carbohydrates_g.sugars.total_g / 50) * 100) + '%' : '0'}</td>
                </tr>
                <tr>
                  <td>Açúcares adicionados (g)</td>
                  <td>{nutritionalValues.carbohydrates_g?.sugars?.added_g ? nutritionalValues.carbohydrates_g.sugars.added_g.toFixed(1).replace('.', ',') : '0'}</td>
                  <td>{nutritionalValues.carbohydrates_g?.sugars?.added_g ? Math.round((nutritionalValues.carbohydrates_g.sugars.added_g / 50) * 100) + '%' : '0'}</td>
                </tr>
                <tr>
                  <td>Proteínas (g)</td>
                  <td>{nutritionalValues.proteins_g ? nutritionalValues.proteins_g.toFixed(1).replace('.', ',') : '0'}</td>
                  <td>{nutritionalValues.proteins_g ? Math.round((nutritionalValues.proteins_g / 50) * 100) + '%' : '0'}</td>
                </tr>
                <tr>
                  <td>Gorduras totais (g)</td>
                  <td>{nutritionalValues.fats_g?.total_g ? nutritionalValues.fats_g.total_g.toFixed(1).replace('.', ',') : '0'}</td>
                  <td>{nutritionalValues.fats_g?.total_g ? Math.round((nutritionalValues.fats_g.total_g / 70) * 100) + '%' : '0'}</td>
                </tr>
                <tr>
                  <td>Gorduras saturadas (g)</td>
                  <td>{nutritionalValues.fats_g?.saturated_g ? nutritionalValues.fats_g.saturated_g.toFixed(1).replace('.', ',') : '0'}</td>
                  <td>{nutritionalValues.fats_g?.saturated_g ? Math.round((nutritionalValues.fats_g.saturated_g / 20) * 100) + '%' : '0'}</td>
                </tr>
                <tr>
                  <td>Gorduras trans (g)</td>
                  <td>{nutritionalValues.fats_g?.trans_g ? nutritionalValues.fats_g.trans_g.toFixed(1).replace('.', ',') : '0'}</td>
                  <td>{nutritionalValues.fats_g?.trans_g ? Math.round((nutritionalValues.fats_g.trans_g / 0) * 100) + '%' : '0'}</td>
                </tr>
                <tr>
                  <td>Fibras alimentares (g)</td>
                  <td>{nutritionalValues.dietaryFiber_g ? nutritionalValues.dietaryFiber_g.toFixed(1).replace('.', ',') : '0'}</td>
                  <td>{nutritionalValues.dietaryFiber_g ? Math.round((nutritionalValues.dietaryFiber_g / 25) * 100) + '%' : '0'}</td>
                </tr>
                <tr>
                  <td>Sódio (mg)</td>
                  <td>{nutritionalValues.sodium_mg ? nutritionalValues.sodium_mg.toFixed(1).replace('.', ',') : '0'}</td>
                  <td>{nutritionalValues.sodium_mg ? Math.round((nutritionalValues.sodium_mg / 2400) * 100) + '%' : '0'}</td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan="3">n/a</td>
              </tr>
            )}
          </tbody>
        </NutritionalTable>
      );
    } else {
      return <div>Conteúdo não disponível</div>;
    }
  };

  if (!ingredientData) {
    return <div>Loading...</div>; // Add a loading state
  }

  return (
    <PerfilIngredienteRoot>
      <FunctionsHeader onLogoClick={() => navigate("/home")} />
      <Breadcrumb path={`Página inicial > Ingredientes > ${ingredientData.name || 'Ingrediente'}`} />
      <MainContent>
        <ContentWrapper>
          <HeroSection>
            <IngredientTitle>{ingredientData.name}</IngredientTitle>
            <IngredientInfo>
              <IngredientImageWrapper onClick={triggerFileInput}>
                <IngredientImage
                  src={ingredientPicUrl || "/default-ingredients.jpg"}
                  alt={ingredientData.name}
                />
                <HiddenFileInput
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </IngredientImageWrapper>
              <ActionButtons>
              <ActionButton
                as={summaryContent === "resumo" ? ActiveButton : "button"}
                onClick={() => setSummaryContent("resumo")}
              >
                Resumo do Ingrediente {/* Substitui "Resumo sobre o ingrediente" */}
              </ActionButton>
              <ActionButton
                as={summaryContent === "precificacao" ? ActiveButton : "button"}
                onClick={() => setSummaryContent("precificacao")}
              >
                Precificação
              </ActionButton>
              <ActionButton
                as={summaryContent === "informacao-nutricional" ? ActiveButton : "button"}
                onClick={() => setSummaryContent("informacao-nutricional")}
              >
                Informação nutricional
              </ActionButton>
              </ActionButtons>
              <IngredientSummary>
                {renderSummaryContent()}
              </IngredientSummary>
            </IngredientInfo>
          </HeroSection>
          <RecipesTitle>Receitas que contêm o ingrediente</RecipesTitle>
          <RecipesWrapper>
            <RecipesGrid>
              {recipes.map((recipe) => (
                <RecipeCard key={recipe.id} onClick={() => handleRecipeClick(recipe.id)}>
                  <RecipeImage src={recipe.image || "/default-ingredients.jpg"} alt={recipe.description} />
                  <span>{recipe.description}</span>
                </RecipeCard>
              ))}
              <AddRecipeCard onClick={() => navigate("/adicionar-prato")}>
                <AddRecipeButton>+</AddRecipeButton>
                <span>Adicionar a uma receita</span>
              </AddRecipeCard>
            </RecipesGrid>
          </RecipesWrapper>
        </ContentWrapper>
      </MainContent>
    </PerfilIngredienteRoot>
  );
};

export default PerfilIngrediente;