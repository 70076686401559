// src/components/ClientFiles.js
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getStorage, ref as storageRef, listAll, getDownloadURL, uploadBytes, deleteObject, getBlob } from "firebase/storage";
import { getDatabase, ref as databaseRef, get } from "firebase/database";
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { FaFolder, FaFile, FaTrash, FaEdit, FaUpload, FaArrowLeft } from "react-icons/fa";

const ClientFilesRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-color: var(--color-gray-200);
  font-family: var(--font-roboto);
  overflow-x: hidden;
  overflow-y: auto;
`;

const Sidebar = styled.div`
  width: 250px;
  background-color: var(--color-white);
  border-right: 1px solid var(--color-gray-300);
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Toolbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`;

const Item = styled.div`
  width: 150px;
  padding: 10px;
  background-color: var(--color-white);
  border: 1px solid var(--color-darkorange);
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--color-darkorange);
    color: var(--color-white);
  }
`;

const ItemIcon = styled.div`
  font-size: 2em;
  margin-bottom: 10px;
`;

const ItemName = styled.div`
  text-align: center;
  word-break: break-all;
`;

const StyledButton = styled.button`
  padding: 5px 10px;
  background-color: var(--color-darkorange);
  color: var(--color-white);
  border: none;
  border-radius: var(--br-5xs);
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: var(--color-orange);
  }
`;

const UploadButton = styled.label`
  padding: 10px 20px;
  background-color: var(--color-darkorange);
  color: var(--color-white);
  border: none;
  border-radius: var(--br-5xs);
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: var(--color-orange);
  }
`;

const BackButton = styled.button`
  padding: 10px 20px;
  background-color: var(--color-gray-500);
  color: var(--color-white);
  border: none;
  border-radius: var(--br-5xs);
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: var(--color-gray-700);
  }
`;

const ClientFiles = () => {
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const [directories, setDirectories] = useState([]);
  const [currentPath, setCurrentPath] = useState("");
  const [clientName, setClientName] = useState("");
  const storage = getStorage();
  const database = getDatabase();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientName = async () => {
      const clientNameRef = databaseRef(database, `users/${id}/name`);
      const snapshot = await get(clientNameRef);
      if (snapshot.exists()) {
        setClientName(snapshot.val());
      } else {
        console.error("No data available");
      }
    };

    const fetchFilesAndDirectories = async () => {
      const listRef = storageRef(storage, `customers/${id}/archive${currentPath}`);
      const res = await listAll(listRef);
      const filePromises = res.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);
        return { name: itemRef.name, url, ref: itemRef };
      });
      const files = await Promise.all(filePromises);
      setFiles(files);
      setDirectories(res.prefixes);
    };

    fetchClientName();
    fetchFilesAndDirectories();
  }, [id, storage, database, currentPath]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileRef = storageRef(storage, `customers/${id}/archive${currentPath}/${file.name}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      setFiles((prevFiles) => [...prevFiles, { name: file.name, url, ref: fileRef }]);
    }
  };

  const handleFileDelete = async (fileRef) => {
    await deleteObject(fileRef);
    setFiles((prevFiles) => prevFiles.filter((file) => file.ref !== fileRef));
  };

  const handleFileRename = async (fileRef, newName) => {
    const newFileRef = storageRef(storage, `customers/${id}/archive${currentPath}/${newName}`);
    const fileBlob = await getBlob(fileRef);
    await uploadBytes(newFileRef, fileBlob);
    await deleteObject(fileRef);
    const url = await getDownloadURL(newFileRef);
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.ref === fileRef ? { name: newName, url, ref: newFileRef } : file
      )
    );
  };

  const handleDirectoryClick = (directory) => {
    setCurrentPath(`${currentPath}/${directory.name}`);
  };

  const handleBackClick = () => {
    if (currentPath) {
      const newPath = currentPath.split("/").slice(0, -1).join("/");
      setCurrentPath(newPath);
    } else {
      navigate(`/cliente/${id}`);
    }
  };

  const handleDirectoryCreate = async () => {
    const newDirectoryName = prompt("Nome do novo diretório:");
    if (newDirectoryName) {
        // Adiciona a barra final para garantir que seja um diretório
        const newDirectoryRef = storageRef(storage, `customers/${id}/archive${currentPath}/${newDirectoryName}/.keep`);

        // Cria um arquivo vazio (Blob) como placeholder para o diretório
        const emptyBlob = new Blob([""], { type: "text/plain" });

        // Faz o upload do arquivo .keep para simular um diretório
        await uploadBytes(newDirectoryRef, emptyBlob);

        // Atualiza o estado para incluir o novo diretório
        setDirectories((prevDirectories) => [
            ...prevDirectories, 
            { name: newDirectoryName, ref: newDirectoryRef }
        ]);
    }
};


  const handleDirectoryDelete = async (directoryRef) => {
    await deleteObject(directoryRef);
    setDirectories((prevDirectories) => prevDirectories.filter((directory) => directory.ref !== directoryRef));
  };

  const handleDirectoryRename = async (directoryRef, newName) => {
    const newDirectoryRef = storageRef(storage, `customers/${id}/archive${currentPath}/${newName}/`);
    await uploadBytes(newDirectoryRef, new Blob());
    await deleteObject(directoryRef);
    setDirectories((prevDirectories) =>
      prevDirectories.map((directory) =>
        directory.ref === directoryRef ? { name: newName, ref: newDirectoryRef } : directory
      )
    );
  };

  return (
    <ClientFilesRoot>
      <Sidebar>
        <h3>Diretórios</h3>
        {directories.map((directory) => (
          <Item key={directory.name} onClick={() => handleDirectoryClick(directory)}>
            <ItemIcon><FaFolder /></ItemIcon>
            <ItemName>{directory.name}</ItemName>
          </Item>
        ))}
        <StyledButton onClick={handleDirectoryCreate}>Criar Diretório</StyledButton>
      </Sidebar>
      <MainContent>
        <FunctionsHeader />
        <Breadcrumb path={`Página inicial > Clientes > ${clientName} > Arquivos`} />
        <Toolbar>
          <BackButton onClick={handleBackClick}><FaArrowLeft /> Voltar</BackButton>
          <UploadButton>
            <input type="file" onChange={handleFileUpload} style={{ display: "none" }} id="file-upload" />
            <label htmlFor="file-upload" style={{ cursor: "pointer" }}><FaUpload /> Upload de Arquivo</label>
          </UploadButton>
        </Toolbar>
        <ContentWrapper>
          <h2 style={{ color: "white" }}>Arquivos do Cliente</h2>
          <ListContainer>
            {files.map((file) => (
              <Item key={file.name}>
                <ItemIcon><FaFile /></ItemIcon>
                <ItemName><a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a></ItemName>
                <div>
                  <StyledButton onClick={() => handleFileDelete(file.ref)}><FaTrash /></StyledButton>
                  <StyledButton onClick={() => handleFileRename(file.ref, prompt("Novo nome:"))}><FaEdit /></StyledButton>
                </div>
              </Item>
            ))}
          </ListContainer>
        </ContentWrapper>
      </MainContent>
    </ClientFilesRoot>
  );
};

export default ClientFiles;