import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, set, onValue } from "firebase/database";
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";

// Styles for the page layout
const AdicionarIngredienteRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
  color: white;
  font-family: var(--font-roboto);
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 60px;
  color: #fff;
  text-align: left;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 60px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1.5rem;
  border: none;
  border-bottom: 2px solid white;
  background-color: transparent;
  color: white;
  text-align: left;
  outline: none;
  caret-color: white;

  ::placeholder {
    color: #999;
    text-align: left;
  }
`;

const ContinueButton = styled.button`
  background-color: #000;
  color: #ff9900;
  padding: 12px 40px;
  font-size: 1.25rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #333;
  }
`;

const BackButton = styled.button`
  background-color: transparent;
  color: #ff9900;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const NutritionForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 40px;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const Label = styled.label`
  color: white;
  flex: 1;
`;

const NutritionInput = styled.input`
  flex: 2;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #333;
  color: white;
  outline: none;

  ::placeholder {
    color: #999;
  }

  /* Accept only numbers */
  &[type="number"] {
    -moz-appearance: textfield;
  }

  /* Remove arrows in input[type=number] for Webkit browsers */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const AdicionarIngrediente = () => {
  const [ingredientName, setIngredientName] = useState("");
  const [showNutritionForm, setShowNutritionForm] = useState(false);
  const [nutrition, setNutrition] = useState({
    valorEnergetico: "",
    carboidratos: "",
    acucaresTotais: "",
    acucaresAdicionados: "",
    proteinas: "",
    gordurasTotais: "",
    gordurasSaturadas: "",
    gordurasTrans: "",
    fibrasAlimentares: "",
    sodio: "",
  });

  const navigate = useNavigate();

  const handleSubmit = () => {
    if (ingredientName) {
      setShowNutritionForm(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNutrition((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleNutritionSubmit = (e) => {
    e.preventDefault();
    const db = getDatabase();
    const ingredientsRef = ref(db, 'ingredients');
    
    onValue(ingredientsRef, (snapshot) => {
      const data = snapshot.val();
      const newId = data ? Math.max(...Object.keys(data).map(Number)) + 1 : 1;
      
      const newIngredient = {
        name: ingredientName,
        nutritionalValues: {
          energyKcal_kcal: nutrition.valorEnergetico,
          carbohydrates_g: {
            total_g: nutrition.carboidratos,
            sugars: {
              total_g: nutrition.acucaresTotais,
              added_g: nutrition.acucaresAdicionados,
            },
          },
          proteins_g: nutrition.proteinas,
          fats_g: {
            total_g: nutrition.gordurasTotais,
            saturated_g: nutrition.gordurasSaturadas,
            trans_g: nutrition.gordurasTrans,
          },
          dietaryFiber_g: nutrition.fibrasAlimentares,
          sodium_mg: nutrition.sodio,
        },
      };

      set(ref(db, `ingredients/${newId}`), newIngredient)
        .then(() => {
          console.log("Ingredient added successfully");
          navigate(`/ingrediente/${newId}`);
        })
        .catch((error) => {
          console.error("Error adding ingredient:", error);
        });
    }, { onlyOnce: true });
  };

  return (
    <AdicionarIngredienteRoot>
      <FunctionsHeader onLogoClick={() => navigate("/home")} />
      <Breadcrumb path="Página inicial > Ingredientes > Criar um ingrediente" />
      <MainContent>
        <ContentWrapper>
          {!showNutritionForm ? (
            <>
              <Title>Como você gostaria de chamar esse ingrediente?</Title>
              <InputWrapper>
                <StyledInput
                  type="text"
                  value={ingredientName}
                  onChange={(e) => setIngredientName(e.target.value)}
                  placeholder="Batata"
                />
              </InputWrapper>
              <ContinueButton onClick={handleSubmit}>Continuar</ContinueButton>
            </>
          ) : (
            <NutritionForm onSubmit={handleNutritionSubmit}>
              <BackButton onClick={() => setShowNutritionForm(false)}>Voltar</BackButton>
              <Title>Preencha as informações nutricionais do ingrediente</Title>
              <FormRow>
                <Label>Valor energético (kcal):</Label>
                <NutritionInput
                  type="number"
                  name="valorEnergetico"
                  value={nutrition.valorEnergetico}
                  onChange={handleInputChange}
                  placeholder="Ex: 200"
                />
              </FormRow>
              <FormRow>
                <Label>Carboidratos (g):</Label>
                <NutritionInput
                  type="number"
                  name="carboidratos"
                  value={nutrition.carboidratos}
                  onChange={handleInputChange}
                  placeholder="Ex: 50"
                />
              </FormRow>
              <FormRow>
                <Label>Açúcares totais (g):</Label>
                <NutritionInput
                  type="number"
                  name="acucaresTotais"
                  value={nutrition.acucaresTotais}
                  onChange={handleInputChange}
                  placeholder="Ex: 10"
                />
              </FormRow>
              <FormRow>
                <Label>Açúcares adicionados (g):</Label>
                <NutritionInput
                  type="number"
                  name="acucaresAdicionados"
                  value={nutrition.acucaresAdicionados}
                  onChange={handleInputChange}
                  placeholder="Ex: 5"
                />
              </FormRow>
              <FormRow>
                <Label>Proteínas (g):</Label>
                <NutritionInput
                  type="number"
                  name="proteinas"
                  value={nutrition.proteinas}
                  onChange={handleInputChange}
                  placeholder="Ex: 10"
                />
              </FormRow>
              <FormRow>
                <Label>Gorduras totais (g):</Label>
                <NutritionInput
                  type="number"
                  name="gordurasTotais"
                  value={nutrition.gordurasTotais}
                  onChange={handleInputChange}
                  placeholder="Ex: 20"
                />
              </FormRow>
              <FormRow>
                <Label>Gorduras saturadas (g):</Label>
                <NutritionInput
                  type="number"
                  name="gordurasSaturadas"
                  value={nutrition.gordurasSaturadas}
                  onChange={handleInputChange}
                  placeholder="Ex: 5"
                />
              </FormRow>
              <FormRow>
                <Label>Gorduras trans (g):</Label>
                <NutritionInput
                  type="number"
                  name="gordurasTrans"
                  value={nutrition.gordurasTrans}
                  onChange={handleInputChange}
                  placeholder="Ex: 0"
                />
              </FormRow>
              <FormRow>
                <Label>Fibras alimentares (g):</Label>
                <NutritionInput
                  type="number"
                  name="fibrasAlimentares"
                  value={nutrition.fibrasAlimentares}
                  onChange={handleInputChange}
                  placeholder="Ex: 3"
                />
              </FormRow>
              <FormRow>
                <Label>Sódio (mg):</Label>
                <NutritionInput
                  type="number"
                  name="sodio"
                  value={nutrition.sodio}
                  onChange={handleInputChange}
                  placeholder="Ex: 100"
                />
              </FormRow>
              <ContinueButton type="submit">Adicionar o ingrediente</ContinueButton>
            </NutritionForm>
          )}
        </ContentWrapper>
      </MainContent>
    </AdicionarIngredienteRoot>
  );
};

export default AdicionarIngrediente;
