import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getDatabase, ref as dbRef, onValue } from "firebase/database";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  color: black; /* Set text color to black */
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: black; /* Set text color to black */
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: black; /* Set text color to black */
`;

const RecipeList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
  color: black; /* Set text color to black */
`;

const RecipeItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? "#f0f0f0" : "white")};
  color: black; /* Set text color to black */

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: var(--color-darkorange);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-orange);
  }
`;

const SelecionarReceitasModal = ({ onClose, onSave }) => {
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const db = getDatabase();
    const recipesRef = dbRef(db, "recipes");

    onValue(recipesRef, (snapshot) => {
      const data = snapshot.val();
      const recipesList = Object.keys(data).map((key) => ({
        id: key,
        ...data[key],
      }));
      setRecipes(recipesList);
    });
  }, []);

  const handleRecipeSelect = (recipe) => {
    setSelectedRecipes((prev) =>
      prev.includes(recipe)
        ? prev.filter((r) => r !== recipe)
        : [...prev, recipe]
    );
  };

  const filteredRecipes = recipes.filter((recipe) =>
    recipe.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <ModalOverlay>
      <ModalContent>
        <Title>Selecione as receitas</Title>
        <SearchBar
          type="text"
          placeholder="Buscar receita..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <RecipeList>
          {filteredRecipes.map((recipe) => (
            <RecipeItem
              key={recipe.id}
              onClick={() => handleRecipeSelect(recipe)}
              selected={selectedRecipes.includes(recipe)}
            >
              <span>{recipe.description}</span>
              <input
                type="checkbox"
                checked={selectedRecipes.includes(recipe)}
                readOnly
              />
            </RecipeItem>
          ))}
        </RecipeList>
        <Button onClick={() => onSave(selectedRecipes)}>Salvar</Button>
        <Button onClick={onClose}>Fechar</Button>
      </ModalContent>
    </ModalOverlay>
  );
};

export default SelecionarReceitasModal;