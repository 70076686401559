import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { auth } from "../../src/firebase";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import Header from "../components/Header"; // Import the Header component

const LoginRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-gray-200);
  text-align: left;
  color: var(--color-gray-200);
  font-family: var(--small);
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: var(--color-white);
  border-radius: var(--br-5xs);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 90%;
  width: 400px;
  margin: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid var(--color-gray-300);
  border-radius: var(--br-5xs);
  font-size: 1rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: var(--color-darkorange);
  color: var(--color-white);
  border: none;
  border-radius: var(--br-5xs);
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: var(--color-orange);
  }
`;

const Error = styled.div`
  color: var(--color-red);
  margin-top: 10px;
  background-color: var(--color-white);
  padding: 5px 10px;
  border-radius: var(--br-5xs);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const ForgotPasswordLink = styled.a`
  color: var(--color-darkorange);
  cursor: pointer;
  margin-top: 10px;
  text-decoration: underline;

  &:hover {
    color: var(--color-orange);
  }
`;

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/home");
    } catch (error) {
      setError("Falha no login. Verifique suas credenciais.");
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError("Por favor, insira seu email para redefinir a senha.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setError("Email de redefinição de senha enviado!");
    } catch (error) {
      setError("Erro ao enviar email de redefinição de senha.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignIn();
    }
  };

  return (
    <>
      <Header />
      <LoginRoot>
        <LoginContainer>
          {error && <Error>{error}</Error>}
          <h2>Entrar</h2>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <Input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <Button onClick={handleSignIn}>Entrar</Button>
          <ForgotPasswordLink onClick={handleForgotPassword}>Esqueci minha senha</ForgotPasswordLink>
        </LoginContainer>
      </LoginRoot>
    </>
  );
};

export default Login;
