import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import FunctionsHeader from "../components/FunctionsHeader"; // Import the header component
import Breadcrumb from "../components/Breadcrumb"; // Import the breadcrumb component

const HomeRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-200);
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-whitesmoke);
  font-family: var(--small);
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
  justify-content: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Keep the content left-aligned within the wrapper */
  max-width: 1200px; /* You can adjust this value to fit your design */
  width: 100%;
  margin-left: 95px; /* Add margin-left to create space on the left */
`;

const WelcomeMessage = styled.h1`
  font-size: 2rem; /* Adjust font size for better visibility */
  color: var(--color-white);
  margin-bottom: 60px; /* Set margin-bottom to 60px */
  font-weight: normal; /* Ensure the text is not bold */
`;

const MenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: flex-start; /* Ensure items are aligned to the start */
  width: 100%;
`;

const MenuItem = styled.div`
  width: 100%;
  max-width: 250px;
  text-align: center;
  cursor: pointer;
`;

const MenuItemImage = styled.img`
  width: 100%;
  border-radius: var(--br-5xl);
  object-fit: cover;
`;

const MenuItemText = styled.div`
  margin-top: 10px;
  font-size: var(--h4-size);
`;

const Home = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");

  const onClientClick = useCallback(() => {
    navigate("/clientes");
  }, [navigate]);

  const onIngredientClick = useCallback(() => {
    navigate("/ingredientes");
  }, [navigate]);

  const onReceipesClick = useCallback(() => {
    navigate("/receitas");
  }, [navigate]);

  const onPrintClick = useCallback(() => {
    navigate("/imprimir");
  }, [navigate]);

  const onLogoClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const handleUserDataFetched = useCallback((userData) => {
    setUserName(userData.name);
  }, []);

  return (
    <HomeRoot>
      <FunctionsHeader
        onLogoClick={onLogoClick}
        onClientClick={onClientClick}
        onIngredientClick={onIngredientClick}
        onReceipesClick={onReceipesClick}
        onUserDataFetched={handleUserDataFetched}
      />
      <MainContent>
        <ContentWrapper>
          <WelcomeMessage>Olá, {userName}! O que deseja fazer hoje?</WelcomeMessage>
          <MenuContainer>
            <MenuItem onClick={onClientClick}>
              <MenuItemImage src="/rectangle-154@2x.png" alt="Clientes" />
              <MenuItemText>Clientes</MenuItemText>
            </MenuItem>
            <MenuItem onClick={onReceipesClick}>
              <MenuItemImage src="/rectangle-155@2x.png" alt="Receitas" />
              <MenuItemText>Receitas</MenuItemText>
            </MenuItem>
            <MenuItem onClick={onIngredientClick}>
              <MenuItemImage src="/rectangle-156@2x.png" alt="Ingredientes" />
              <MenuItemText>Ingredientes</MenuItemText>
            </MenuItem>
            <MenuItem onClick={onPrintClick}>
              <MenuItemImage src="/rectangle-157@2x.png" alt="Impressão" />
              <MenuItemText>Impressão</MenuItemText>
            </MenuItem>
          </MenuContainer>
        </ContentWrapper>
      </MainContent>
    </HomeRoot>
  );
};

export default Home;
