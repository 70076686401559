import React, { useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import FunctionsHeader from "../components/FunctionsHeader"; // Import the header component
import Breadcrumb from "../components/Breadcrumb"; // Import the breadcrumb component

const ClientesRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-200);
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-whitesmoke);
  font-family: var(--small);
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const MenusParent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  width: 100%;
`;

const MenuItemContainer = styled.div`
  width: 100%;
  max-width: 300px;
  text-align: center;
  cursor: pointer;
`;

const MenuItem = styled.div`
  width: 100%;
  height: 200px;
  background-color: var(--color-black);
  border-bottom: 2px solid var(--color-orange);
  border-radius: var(--br-base);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
`;

const MenuItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--br-base);
`;

const MenuItemIcon = styled.img`
  width: 50px;
  height: 50px;
`;

const MenuItemText = styled.div`
  color: var(--color-white);
  font-size: 1rem;
  margin-top: 10px;
`;

const Tooltip = styled.div`
  visibility: hidden;
  width: 200px;
  background-color: var(--color-black);
  color: var(--color-white);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--color-black) transparent transparent transparent;
  }
`;

const MenuItemContainerWithTooltip = styled(MenuItemContainer)`
  position: relative;
  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`;

const Clientes = () => {
  const navigate = useNavigate();

  const onMenusContainerClick = useCallback(() => {
    navigate("/selecione-um-cliente");
  }, [navigate]);

  return (
    <ClientesRoot>
      <FunctionsHeader
        onLogoClick={() => navigate("/home")}
        onClientClick={onMenusContainerClick}
        onIngredientClick={() => {}}
      />
      <Breadcrumb path="Página inicial > Clientes" />
      <MainContent>
        <MenusParent>
          <MenuItemContainer onClick={onMenusContainerClick}>
            <MenuItem>
              <MenuItemImage src="/rectangle-154@2x.png" alt="Todos os clientes" />
            </MenuItem>
            <MenuItemText>Todos os clientes</MenuItemText>
          </MenuItemContainer>
          <MenuItemContainerWithTooltip>
            <MenuItem>
              <MenuItemIcon src="/icbaselinepersonaddalt11.svg" alt="Criar um cliente" />
            </MenuItem>
            <MenuItemText>Criar um cliente</MenuItemText>
            <Tooltip>
            A criação de novos clientes deve ser realizada por meio do CRM disponível no site SoulBen no Wix. Este sistema de nutrição integra-se automaticamente, reconhecendo os clientes adicionados pelo CRM.
            </Tooltip>
          </MenuItemContainerWithTooltip>
        </MenusParent>
      </MainContent>
    </ClientesRoot>
  );
};

export default Clientes;
