import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, onValue, set } from "firebase/database";
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";

const AdicionarReceitaRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
  color: white;
  font-family: var(--font-roboto);
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 60px;
  color: #fff;
  text-align: left;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 60px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1.5rem;
  border: none;
  border-bottom: 2px solid white;
  background-color: transparent;
  color: white;
  text-align: left;
  outline: none;
  caret-color: white;

  ::placeholder {
    color: #999;
    text-align: left;
  }
`;

const ContinueButton = styled.button`
  background-color: #000;
  color: #ff9900;
  padding: 12px 40px;
  font-size: 1.25rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #333;
  }
`;

const BackButton = styled.button`
  background-color: transparent;
  color: #ff9900;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

const IngredientList = styled.ul`
  width: 100%;
  list-style-type: none;
  padding: 0;
`;

const IngredientItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #333;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff9900;
  }
`;

const IngredientCheckbox = styled.input`
  margin-right: 10px;
`;

const QuantityInput = styled.input`
  width: 100px;
  padding: 5px;
  font-size: 1rem;
  background-color: #333;
  border: 1px solid #fff;
  border-radius: 5px;
  color: white;
`;

const AdicionarReceita = ({ initialRecipeName = "", initialIngredients = {}, initialInstructions = "", onRecipeSubmit }) => {
  const [recipeName, setRecipeName] = useState(initialRecipeName);
  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState(initialIngredients);
  const [quantities, setQuantities] = useState({});
  const [instructions, setInstructions] = useState(initialInstructions);
  const [query, setQuery] = useState("");
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase();
    const ingredientsRef = ref(db, "ingredients");
    onValue(ingredientsRef, (snapshot) => {
      const data = snapshot.val();
      const formattedIngredients = Object.keys(data).map((key) => ({
        id: key,
        name: data[key].name,
      }));
      setIngredients(formattedIngredients);
    });
  }, []);

  const handleIngredientSelect = (id) => {
    setSelectedIngredients((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleQuantityChange = (e, id) => {
    const { value } = e.target;
    setQuantities((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleNextStep = () => {
    if (step === 1 && !recipeName) {
      alert("Por favor, insira o nome da receita.");
    } else if (step === 2 && Object.keys(selectedIngredients).length === 0) {
      alert("Selecione pelo menos um ingrediente.");
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleRecipeSubmit = () => {
    const db = getDatabase();
    const recipesRef = ref(db, "recipes");
  
    onValue(
      recipesRef,
      (snapshot) => {
        const data = snapshot.val();
        const newId = data ? Math.max(...Object.keys(data).map(Number)) + 1 : 1;
  
        const newRecipe = {
          description: recipeName,
          ingredients: Object.keys(quantities).map((id) => ({
            id: id,
            name: ingredients.find((ingredient) => ingredient.id === id).name,
            quantity_g_or_ml: parseInt(quantities[id], 10)
          })),
          instructions: instructions || "Sem instruções adicionais.",
          source: "SOUL_BEN",
          source_id: newId.toString()
        };
  
        set(ref(db, `recipes/${newId}`), newRecipe)
          .then(() => {
            if (onRecipeSubmit) {
              onRecipeSubmit(newId);
            }
            navigate(`/receita/${newId}`);
          })
          .catch((error) => {
            console.error("Erro ao salvar a receita:", error);
          });
      },
      { onlyOnce: true }
    );
  };

  return (
    <AdicionarReceitaRoot>
      <FunctionsHeader onLogoClick={() => navigate("/home")} />
      <Breadcrumb path="Página inicial > Receitas > Criar uma receita" />
      <MainContent>
        <ContentWrapper>
          {step === 1 && (
            <>
              <Title>Como você gostaria de chamar essa receita?</Title>
              <InputWrapper>
                <StyledInput
                  type="text"
                  value={recipeName}
                  onChange={(e) => setRecipeName(e.target.value)}
                  placeholder="Ex: Salada de Frango"
                />
              </InputWrapper>
              <ContinueButton onClick={handleNextStep}>Continuar</ContinueButton>
            </>
          )}

          {step === 2 && (
            <>
              <ButtonWrapper>
                <BackButton onClick={handlePreviousStep}>Voltar</BackButton>
                <ContinueButton onClick={handleNextStep}>Continuar</ContinueButton>
              </ButtonWrapper>
              <Title>Selecione os ingredientes</Title>
              <StyledInput
                type="text"
                placeholder="Buscar ingrediente"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <IngredientList>
                {ingredients
                  .filter((ingredient) =>
                    ingredient.name.toLowerCase().includes(query.toLowerCase())
                  )
                  .map((ingredient) => (
                    <IngredientItem
                      key={ingredient.id}
                      onClick={() => handleIngredientSelect(ingredient.id)}
                    >
                      <IngredientCheckbox
                        type="checkbox"
                        checked={selectedIngredients[ingredient.id] || false}
                        onChange={() => handleIngredientSelect(ingredient.id)}
                      />
                      {ingredient.name}
                    </IngredientItem>
                  ))}
              </IngredientList>
            </>
          )}

          {step === 3 && (
            <>
              <ButtonWrapper>
                <BackButton onClick={handlePreviousStep}>Voltar</BackButton>
                <ContinueButton onClick={handleNextStep}>Continuar</ContinueButton>
              </ButtonWrapper>
              <Title>Selecione a quantidade de ingredientes</Title>
              <IngredientList>
                {Object.keys(selectedIngredients)
                  .filter((id) => selectedIngredients[id])
                  .map((id) => {
                    const ingredient = ingredients.find((i) => i.id === id);
                    if (!ingredient) return null; // Add this check
                    return (
                      <IngredientItem key={id}>
                        <span>{ingredient.name}</span>
                        <QuantityInput
                          type="number"
                          value={quantities[id] || ""}
                          onChange={(e) => handleQuantityChange(e, id)}
                          placeholder="Gramas"
                        />
                      </IngredientItem>
                    );
                  })}
              </IngredientList>
            </>
          )}

          {step === 4 && (
            <>
              <ButtonWrapper>
                <BackButton onClick={handlePreviousStep}>Voltar</BackButton>
                <ContinueButton onClick={handleRecipeSubmit}>Finalizar Receita</ContinueButton>
              </ButtonWrapper>
              <Title>Opcional: Insira as instruções de preparo</Title>
              <InputWrapper>
                <StyledInput
                  type="text"
                  value={instructions}
                  onChange={(e) => setInstructions(e.target.value)}
                  placeholder="Ex: Grelhe o frango..."
                />
              </InputWrapper>
            </>
          )}
        </ContentWrapper>
      </MainContent>
    </AdicionarReceitaRoot>
  );
};

export default AdicionarReceita;
