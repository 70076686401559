import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getDatabase, ref as dbRef, onValue, update } from "firebase/database";
import AdicionarReceita from "./AdicionarReceita";

const AdicionarVariante = () => {
  const { recipeId } = useParams();
  const [recipe, setRecipe] = useState(null);

  useEffect(() => {
    if (recipeId) {
      const db = getDatabase();
      const recipeRef = dbRef(db, `recipes/${recipeId}`);

      onValue(recipeRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setRecipe(data);
        } else {
          console.error("Recipe not found");
        }
      });
    }
  }, [recipeId]);

  const handleRecipeSubmit = (newVariantId) => {
    const db = getDatabase();
    const recipeRef = dbRef(db, `recipes/${recipeId}/variants`);

    update(recipeRef, {
      [newVariantId]: true,
    }).catch((error) => {
      console.error("Erro ao atualizar a receita original:", error);
    });
  };

  if (!recipe) {
    return <div>Loading...</div>;
  }

  return (
    <AdicionarReceita
      initialRecipeName={`Variante de ${recipe.description}`}
      initialIngredients={recipe.ingredients}
      initialInstructions={recipe.instructions}
      onRecipeSubmit={handleRecipeSubmit}
    />
  );
};

export default AdicionarVariante;