import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getDatabase, ref as dbRef, onValue, get } from "firebase/database";
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";
import styled from "styled-components";

const VerVariantesRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-200);
  font-family: var(--font-roboto);
  overflow-x: hidden;
  overflow-y: auto;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const VariantsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  width: 100%;
`;

const VariantCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const VariantImage = styled.img`
  width: 100%;
  max-width: 220px;
  height: 156px;
  border-radius: var(--br-5xs);
  object-fit: cover;
  border: 1px solid orange;
`;

const VariantName = styled.div`
  margin-top: 10px;
  font-size: 1rem;
  color: var(--color-white);
`;

const VerVariantes = () => {
  const { recipeId } = useParams();
  const [variants, setVariants] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (recipeId) {
      const db = getDatabase();
      const recipeRef = dbRef(db, `recipes/${recipeId}`);

      onValue(recipeRef, (snapshot) => {
        const data = snapshot.val();
        if (data && data.variants) {
          const variantIds = Object.keys(data.variants);
          const variantPromises = variantIds.map((variantId) =>
            get(dbRef(db, `recipes/${variantId}`))
          );

          Promise.all(variantPromises).then((variantSnapshots) => {
            const variantData = variantSnapshots.map((snapshot) => ({
              id: snapshot.key,
              ...snapshot.val()
            }));
            setVariants(variantData);
          });
        } else {
          console.error("No variants found");
        }
      });
    }
  }, [recipeId]);

  return (
    <VerVariantesRoot>
      <FunctionsHeader onLogoClick={() => navigate("/home")} />
      <Breadcrumb path={`Página inicial > Receitas > Variantes`} />
      <MainContent>
        <ContentWrapper>
          <VariantsGrid>
            {variants.map((variant) => (
              <VariantCard key={variant.id} onClick={() => navigate(`/receita/${variant.id}`)}>
                <VariantImage src={variant.image || "/default-recipe-image.webp"} alt={variant.description} />
                <VariantName>{variant.description}</VariantName>
              </VariantCard>
            ))}
          </VariantsGrid>
        </ContentWrapper>
      </MainContent>
    </VerVariantesRoot>
  );
};

export default VerVariantes;